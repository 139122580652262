<template>
  <v-dialog v-model="memberRequestEdit" max-width="600" scrollable>

    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3">
          <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
            <h2 class="title text-start text-break">
              Edit Request
            </h2>
          </v-col>
          <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
            <v-btn icon color="grey lighten-1" @click="memberRequestEdit = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>

        <div style="margin-bottom: 25px">

          <v-col>

            <h1 class="display-1 text-left px-2 px-sm-6 py-4 pb-3">
              Member Booking
            </h1>
            <v-form class="px-2 px-sm-6 py-4" ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-file-input label="Upload Logo" placeholder="Choose File*" prepend-icon append-icon="mdi-paperclip"
                    clear-icon="mdi-close" v-model="logo" @change="fileChanged" ref="fileClearUpload"
                    truncate-length="14" :error-messages="errorFile" multiple clearable show-size outlined required
                    :key="fileUploadKey" accept="image/*" hide-details="auto" class="py-8"></v-file-input>
                  <v-img v-if="imagePreview" :src="imagePreview" width="150px" height="100px" class="mb-4"
                    contain></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field label="Name" v-model="name" outlined required
                    :error-messages="errorName"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field label="Email" v-model="email" outlined required
                    :error-messages="errorEmail"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-textarea @keydown.enter="handlePoints" label=" Intro (Max. 500 Characters)*" v-model="intro"
                    outlined required :error-messages="errorIntro" :counter="500"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col cols="4" class="py-0">
                  <v-menu ref="menucolourOne" v-model="menucolourOne" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <div style="height: 10px;margin-bottom: 7px; border-radius: 3px"
                        :style="{ backgroundColor: colourOne }"></div>
                      <v-text-field v-bind="attrs" v-on="on" label="Primary Colour" v-model="colourOne" outlined
                        required :error-messages="errorcolour" prepend-inner-icon="mdi-palette"></v-text-field>
                    </template>
<v-card>
  <v-color-picker v-model="colourOne" hide-canvas></v-color-picker>
</v-card>
</v-menu>
</v-col>
<v-col cols="4" class="py-0">
  <v-menu ref="menucolourTwo" v-model="menucolourTwo" :close-on-content-click="false" transition="scale-transition"
    offset-y min-width="290px">
    <template v-slot:activator="{ on, attrs }">
                                            <div style="height: 10px;margin-bottom: 7px; border-radius: 3px"
                                              :style="{ backgroundColor: colourTwo }"></div>
                                            <v-text-field v-bind="attrs" v-on="on" label="Secondary Colour" v-model="colourTwo" outlined
                                              required :error-messages="errorcolour" prepend-inner-icon="mdi-palette"></v-text-field>
                                          </template>
    <v-card>
      <v-color-picker v-model="colourTwo" hide-canvas></v-color-picker>
    </v-card>
  </v-menu>
</v-col> -->
                <v-col cols="12" class="py-0">
                  <v-menu ref="menu" v-model="menu" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="290px" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field style="margin-top: 17px;" v-model="EndDate" label="End Date*"
                        append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined required
                        :error-messages="errorDate" @click="menu = true"></v-text-field>
                    </template>
                    <v-date-picker v-model="EndDate" no-title scrollable @input="menu = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="py-0">
                  <v-textarea label="Additional Notes (Max. 500 Characters)*" v-model="notes" outlined required
                    :error-messages="errorNotes" :counter="500"></v-textarea>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field label="Stay22 Map Link" v-model="mapLink" outlined required
                    :error-messages="errorMapLink"></v-text-field>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field label="Allez Link" v-model="allezLink" outlined required
                    :error-messages="errorAllezLink"></v-text-field>
                </v-col>

              </v-row>
              <v-row align-content="center" class="fill-height" justify="center" v-if="submissionStatus !== null">
                <v-col class="text-subtitle-1 text-center" cols="12">
                  {{ submissionStatus }}
                </v-col>
                <v-col cols="6">
                  <v-progress-linear color="cyan" indeterminate></v-progress-linear>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-0 d-flex  align-center justify-center">
                  <v-btn class="align-self-center px-8" color="secondary" @click="submitFn" tile depressed
                    :loading="isLoadingAPI">
                    Submit
                  </v-btn>
                </v-col>

              </v-row>
            </v-form>
          </v-col>


        </div>

      </v-card-text>

    </v-card>
  </v-dialog>
</template>
<script>

import AdminApiCalls from "../../../services/admin.sevice";

export default {
  name: "memberRequestEdit",
  props: {
    memberItem: []
  },
  data() {
    return {
      id: null,
      memberRequestEdit: false,
      logo: null,
      name: null,
      email: null,
      intro: null,
      notes: null,
      EndDate: null,
      menu: false,
      menucolourOne: false,
      menucolourTwo: false,
      colourOne: '',
      colourTwo: '',
      imagePreview: null,
      mapLink: null,
      allezLink: null,
      errorName: null,
      errorEmail: null,
      errorIntro: null,
      errorNotes: null,
      errorFile: null,
      errorDate: null,
      errorcolour: null,
      errorMapLink: null,
      errorAllezLink: null,
      submissionStatus: null,
      user: null,
      userId: null,
      userType: null,
      memberLogo: null,
      membereventId:null
    };
  },
  watch: {
    memberItem() {

      this.id = this.memberItem._id;
      this.logo = null;
      this.name = this.memberItem.name || '';
      this.email = this.memberItem.email || '';
      this.intro = this.memberItem.description || '';
      // this.colourOne = this.memberItem.colors[0] || '';
      // this.colourTwo = this.memberItem.colors[1] || '';
      this.EndDate = this.memberItem.endDate || '';
      this.notes = this.memberItem.notes || '';
      this.imagePreview = this.memberItem.logo ? this.memberItem.logo : null;
      this.userType = this.memberItem.userType;
      this.userId = this.memberItem.createdBy;
      this.mapLink = this.memberItem.map || '';
      this.allezLink = this.memberItem.allez || '';
      this.membereventId = this.memberItem.eventId || ''

    },
    intro(val) {
      if (val) {
        if (val.length > 500) {
          this.errorIntro = "Intro must not exceed 500 characters"
        } else if (val.length < 3) {
          this.errorIntro = "Intro required minimum 3 characters"
        } else {
          this.errorIntro = null
        }
        this.handlePoints();
      }
    },
    name(value) {
      if (value) {
        this.errorName = null
      }
    },
    email(value) {
      if (!this.validEmail(value)) {
        this.errorEmail = "Please enter a valid email."

      } else {
        this.errorEmail = null
      }
    },
    // colourOne(val){
    //     if (val) {
    //         this.errorcolourOne = null
    //     } 
    // },
    // colourTwo(val){
    //     if (val) {
    //         this.errorcolourTwo = null
    //     } 
    // },
    EndDate(val) {
      if (val) {
        this.errorDate = null
      }
    },
    logo(val) {
      if (val) {
        this.errorFile = null
      }

    },
    notes(val) {
      if (val) {
        if (val.length > 500) {
          this.errorNotes = "Notes must not exceed 500 characters"
        } else if (val.length < 3) {
          this.errorNotes = "Notes required minimum 3 characters"
        } else {
          this.errorNotes = null
        }
      }
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.handlePoints();
    });
  },
  methods: {
    async submitFn() {
      if (this.name && this.email && this.intro && this.notes && this.EndDate && this.imagePreview) {

        this.submissionStatus = "Uploading Logo";
        const logoUrl = await this.uploadImage();
        this.submissionStatus = "Submitting Your Request";
        const Status = this.mapLink.includes('https') && this.allezLink.includes('https') ? "completed" : "edited";
        const payload = {
          id: this.id,
          logo: logoUrl,
          name: this.name,
          email: this.email,
          // colors: [this.colourOne, this.colourTwo],
          endDate: this.EndDate,
          description: this.intro,
          notes: this.notes,
          createdBy: this.userId,
          userType: this.userType,
          status: Status,
          allez: this.allezLink,
          map: this.mapLink,
          eventId : this.membereventId
        }
        const res = await AdminApiCalls.updateMemberBooking(payload);
        this.submissionStatus = null;
        console.log(res)
        if (res.status === 200) {
          this.memberRequestEdit = false;
        }
        this.$emit("reload")
      } else {
        this.validateForm()
      }

    },
    handlePoints() {
      const texts = this.intro.split('\n');
            let updated = '';

            for (let i = 0; i < texts.length; i++) {
                const line = texts[i].trim();

                if (line) {
                    updated += line;
                }

                if (i < texts.length - 1) {
                    updated += '\n';
                }
            }

            this.intro = updated;
    },
    // Adds a bullet point on pressing Enter
    addBulletPoint() {
      const textarea = this.$refs.introTextarea.$el.querySelector('textarea');
      const cursorPosition = textarea.selectionStart;
      const textBefore = this.intro.substring(0, cursorPosition);
      const textAfter = this.intro.substring(cursorPosition);
      this.intro = `${textBefore}\n• ${textAfter}`;
      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = cursorPosition + 3; // Adjust cursor position to be after the new bullet point
      });
    },
    open() {
      this.memberRequestEdit = true;
    },
    fileChanged() {
      console.log(this.user)
      if (this.logo && this.logo.length > 0) {
        const file = this.logo[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.imagePreview = null;
      }
    },
    async uploadImage() {
      const toBase64 = file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        });
      if (this.imagePreview && !this.imagePreview.includes('https')) {

        const payloadImg = {
          Path: this.userId + this.logo[0].name.toLowerCase().replace(/[^a-zA-Z.]/g, ''),
          Base64: await toBase64(this.logo[0])
        }
        const imgResponse = await AdminApiCalls.uploadLogo(payloadImg);
        return imgResponse.data.content;
      } else {
        return this.imagePreview
      }
    },
    validateForm() {
      if (!this.email) {
        this.errorEmail = "Please fill out this field"
        let payload = {
          snackBarText: this.errorEmail,
          snackBarColor: 'error'
        }
        this.$store.dispatch('notify/setSnackbarMessage', payload)

      } if (!this.name) {
        this.errorName = "Please fill out this field"
        let payload = {
          snackBarText: this.errorName,
          snackBarColor: 'error'
        }
        this.$store.dispatch('notify/setSnackbarMessage', payload)

      }
      if (!this.intro) {
        this.errorIntro = "Please fill out this field"
        let payload = {
          snackBarText: this.errorIntro,
          snackBarColor: 'error'
        }
        this.$store.dispatch('notify/setSnackbarMessage', payload)

      }
      if (!this.EndDate) {
        this.errorDate = "Please fill out this field"
        let payload = {
          snackBarText: this.errorDate,
          snackBarColor: 'error'
        }
        this.$store.dispatch('notify/setSnackbarMessage', payload)

      }
      if (!this.logo.length > 0) {
        this.errorFile = "Please fill out this field"
        let payload = {
          snackBarText: this.errorFile,
          snackBarColor: 'error'
        }
        this.$store.dispatch('notify/setSnackbarMessage', payload)

      }
      // if (!this.colourOne) {
      //     this.errorcolourOne = "Please fill out this field"
      //     let payload = {
      //         snackBarText: this.errorcolourOne,
      //         snackBarColor: 'error'
      //     }
      //     this.$store.dispatch('notify/setSnackbarMessage', payload)

      // }
      // if (!this.colourTwo) {
      //     this.errorcolourTwo = "Please fill out this field"
      //     let payload = {
      //         snackBarText: this.errorcolourTwo,
      //         snackBarColor: 'error'
      //     }
      //     this.$store.dispatch('notify/setSnackbarMessage', payload)

      // }
      if (!this.notes) {
        this.errorNotes = "Please fill out this field"
        let payload = {
          snackBarText: this.errorNotes,
          snackBarColor: 'error'
        }
        this.$store.dispatch('notify/setSnackbarMessage', payload)

      }

    },
    validEmail(value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    },

  },
};
</script>
<style scoped>
.img-placeholder {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.img-cover-placeholder {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 5px;
}
</style>
<style>
.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>