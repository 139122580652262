<template>
    <v-dialog v-model="deleteDialog"  persistent max-width="400">
     
      <v-card>
        <v-card-title>
          <h3 class="title text-start">
            Are you sure you want to delete '?
          </h3>
        </v-card-title>
        <v-card-text>
          <p class="subtitle-2 text-left">
            You will be deleting all the data associated with this
           .
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="deleteDialog = false"
            >No, Cancel</v-btn
          >
          <!-- until test end -->
          <!-- <v-btn color="error" text @click="deleteFn(item)">Yes, Delete</v-btn> -->
          <v-btn color="error" text @click="deleteFn()">Yes, Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "DeleteMOdal",
    props: {
      item: []
      // itemType: String
    },
    data() {
      return {
        deleteDialog: false
      };
    },
    methods: {
      deleteFn() {
        this.deleteDialog = false;
        this.$emit("setDelete", this.item);
        this.$emit("reload");
        
      },
      open() {
      this.deleteDialog = true;
    },
    }
  };
  </script>