<template>
    <!-- eslint-disable -->
    <v-container id="rfp-page" fluid tag="section">
        <v-row justify="center">
            <v-col cols="12">
                <base-material-card color="primary" class="px-5 py-3">
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Bookings
                        </div>
                        <div class="subtitle-1 font-weight-light">
                            A list of all Bookings
                        </div>
                    </template>
                    <v-card-text>
                        <v-row class="mx-0" align="center" justify="center" justify-md="start">
                            <v-col cols="12" md="7" lg="4" class="py-5 mr-1">
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                    outlined hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="7" class="px-5">
                                <v-row class="align-center">
                                    <v-col class="d-flex flex-row align-center" cols="12" md="5" lg="5">
                                        <span class="text-center subtitle-1 text-capitalize px-4"
                                            style="font-weight: 800;"
                                            :style="{ color: eventBooking ? '#35A7FF' : '#313638' }">
                                            Event Bookings {{ eventBooking ? 'Activated' : 'Deactivated' }}
                                        </span>
                                        <v-switch class="text-center" v-model="eventBooking"></v-switch>
                                    </v-col>
                                    <v-col class="d-flex  flex-row align-center" cols="12" md="5" lg="5">
                                        <span class="text-center subtitle-1 text-capitalize px-4"
                                            style="font-weight: 800;"
                                            :style="{ color: memberBooking ? '#35A7FF' : '#313638' }">
                                            Member Bookings {{ memberBooking ? 'Activated' : 'Deactivated' }}
                                        </span>
                                        <v-switch class="text-center" v-model="memberBooking"></v-switch>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0" align="center" justify="center" justify-md="start">
                            <v-col cols="12" class="px-0">
                                <v-data-table :headers="headers" :search="search" :items="items" :loading="isLoading">
                                    <template v-slot:item.created_at="{ item }">{{
                                    item.created_at.split("T")[0]
                                }}</template>
                                    <template v-slot:item.userType="{ item }">
                                        <span class="text-center subtitle-2 text-capitalize">{{
                                    item.userType == "citySubscriber"
                                        ? "City Subscriber"
                                        : item.userType
                                }}</span>
                                    </template>


                                    <template v-slot:item.status="{ item }">
                                        <v-chip ripple
                                            style="width: 150px; font-size: large; font-weight: bold; color: white; text-align: center; text-transform: uppercase;"
                                            label
                                            :color="item.status == 'new' ? 'primary' : item.status == 'completed' ? 'green' : item.status == 'edited' ? 'orange' : 'red'"
                                            variant="flat">
                                            <b>{{ item.status }}</b>
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon color="grey lighten-1" v-bind="attrs" v-on="on">
                                                    <font-awesome-icon :icon="['fas', 'chevron-down']" />
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item v-if="item.status !== 'end'" @click="editItem(item)">
                                                    <v-list-item-title>Edit</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="deleteItem(item)">
                                                    <v-list-item-title>Delete</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="viewMore(item)">
                                                    <v-list-item-title>Dedicated Page</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>

                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-chip class="ma-2" color="secondary" label v-if="!eventBooking">
                        <b>The event booking feature is currently disabled. Organizers and city subscribers will not be
                            able to use this
                            feature until it is re-enabled.</b>
                    </v-chip>
                    <br />
                    <v-chip class="ma-2" color="secondary" label v-if="!memberBooking">
                        <b> The member booking feature is currently disabled. Organizers will not be able to use this
                            feature until it is
                            re-enabled.</b>
                    </v-chip>
                </base-material-card>
            </v-col>
        </v-row>
        <MemberRequestEditModal :memberItem="requestedItem" ref="memberRequestEditModal" @reload="reloadfn" />
        <EventRequestEditModal :eventItem="requestedItem" ref="eventRequestEditModal" @reload="reloadfn" />
        <DeleteConfirmation ref="deletedialogmodal" :item="requestedItem" @setDelete="deleteFn" @reload="reloadfn" />
    </v-container>
</template>

<script>
import AdminApiCalls from "../../../services/admin.sevice";
import { FEATURES } from "../../../util/Features";
import MemberRequestEditModal from './MemberRequestEditModal.vue';
import EventRequestEditModal from './EventRequestEditModal.vue';
import DeleteConfirmation from "./deleteModal.vue";
export default {
    name: "Bookings",
    components: {
        MemberRequestEditModal,
        EventRequestEditModal,
        DeleteConfirmation
    },
    data() {
        return {
            search: "",
            isLoading: false,
            deleteDialog: false,
            requestedItem: [],
            headers: [

                {
                    sortable: true,
                    text: "Date",
                    value: "created_at",
                    class: "primary--text title",
                },
                {
                    sortable: true,
                    text: "Name",
                    value: "name",
                    class: "primary--text title",
                },
                {
                    sortable: true,
                    text: "Email",
                    value: "email",
                    class: "primary--text title",
                },
                {
                    sortable: true,
                    text: "Type",
                    value: "userType",
                    class: "primary--text title",
                },

                {
                    sortable: true,
                    text: "Request Status",
                    value: "status",
                    class: "primary--text title",
                },
                {
                    sortable: false,
                    text: "Actions",
                    value: "actions",
                    class: "primary--text title",
                },
            ],
            items: [],
            eventBooking: false,
            memberBooking: false,
        };
    },
    async mounted() {
        this.loadRequests();
    },
    async beforeMount() {
        this.updateFromDB();
    },
    watch: {
        eventBooking(val) {
            if (!this.isLoading) this.updateFeatureStatus(FEATURES.EVENT_BOOKING, val);
        },
        memberBooking(val) {
            if (!this.isLoading) this.updateFeatureStatus(FEATURES.MEMBER_BOOKING, val);
        },
        search() {
            this.loadRequests();
        }
    },
    methods: {
        async updateFeatureStatus(feature, status) {
            const payload = {
                feature: feature,
                status: status
            }
            this.isLoading = true;
            await AdminApiCalls.setFeature(payload);
            this.updateFromDB()
        },
        async updateFromDB() {
            this.isLoading = true;
            // logic
            await AdminApiCalls.getFeature(FEATURES.EVENT_BOOKING).then(res => {
                try {
                    this.eventBooking = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
                } catch (e) {
                    this.eventBooking = false;
                }
            });
            await AdminApiCalls.getFeature(FEATURES.MEMBER_BOOKING).then(res => {
                try {
                    this.memberBooking = res.data.details.status === 'true' || res.data.details.status === true ? true : false;
                } catch (e) {
                    this.memberBooking = false;
                }
            });
            this.isLoading = false;
        },
        async loadRequests() {
            const data = await AdminApiCalls.getBookingRequests(this.search);
            try {
                this.items = data.data.result;
            } catch (e) {
                this.items = [];
            }
        },

        editItem(item) {
            this.requestedItem = item
            item.bookingType === "Member Booking" ? this.$refs.memberRequestEditModal.open() : this.$refs.eventRequestEditModal.open();

        },
        deleteItem(item) {
            this.requestedItem = item
            this.$refs.deletedialogmodal.open()
        },
        deleteFn(val) {
            AdminApiCalls.deleteBooking(val._id);
            this.reloadfn()

        },
        reloadfn() {
            this.loadRequests();
        },
        viewMore(data) {
            const currentUrl = window.location.href;
            let baseUrl;

            if (currentUrl.includes("localhost")) {
                baseUrl = "http://localhost:3000/event/";
            } else if (currentUrl.includes("staging")) {
                baseUrl = "https://staging.conferli.com/event/";
            } else {
                baseUrl = "https://conferli.com/event/";
            }

            // const userId = data.createdBy;
            const eventName = data.bookingType === 'Member Booking' ? data.name : data.eventName;

            const eventUrl = `${baseUrl}${data.eventId}/${eventName}`;

            window.open(eventUrl, '_blank');
        }

    },
};
</script>